import { apiAxiosV1 } from '@/configs/axios';
import { API_END_POINT_V2 } from '@/configs/config';
import Referral, { ReferralProgress } from '@/model/Referral';
const MOCK_REFERRAL = {
  is_capped: true,
  max_referrals: 0,
  progress: [
    {
      earning: {
        amount: 10,
        currency: 'Bharat',
      },
      offers_completed: 0,
      offers_requried: 0,
      user_id: 'aljfa-asfajsk-asfjansk',
      user_name: 'Test User 1',
    },
  ],
};
export const ReferralService = {
  async getReferralCode() {
    const res = await apiAxiosV1.get('/referral/token/get');
    return res?.data;
  },
  async getReferralLink() {
    const res = await apiAxiosV1.get('/referral/token/get');
    return res?.data;
  },

  async getReferralList({ page = 1 }) {
    const params = {
      page,
    };
    const res = await apiAxiosV1.get(API_END_POINT_V2 + '/referral/progress', {
      params,
    });
    return ReferralProgress.fromAll(res?.data.progress);
  },
  async getTotalReferral() {
    const res = await apiAxiosV1.get('/referral/progress/count');
    return res?.data?.usage_count;
  },
};
